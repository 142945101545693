import React, { useRef } from "react";
import Iconify from "../components/Iconify";
import "./style.scss";
import Tuffy_Pay from "../../../assets/Tuffy_pay.png";

import { Button, Box, Typography, Grid } from "@mui/material";
import Footer from "./footer";
import Delighted from "./pay/delighted";
import WhatIs from "./pay/whatIs";
import BtafPayWork from "./pay/btafPayWork";
import Layout from "./layouts";
import Contents from "./pay/contents";
import Merchants from "./pay/merchants";
import Contact from "./pay/contact";

const Pay = () => {
  const viewRef = useRef(null);
  return (
    <>
      <Box className="main">
        <Box className="section banner banner-section">
          <Box className="container">
            <Box className="before-styles-pay">
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Box className="banner-inner" sx={{ marginTop: "8rem" }}>
                    <h1 className="heading-xl-pay">
                      Use BTAF tokens to purchase goods and services on
                      platforms globally
                    </h1>
                    <Button
                      sx={{
                        textTransform: "none",
                        borderRadius: "1rem",
                        fontWeight: "800",
                        padding: "1rem",
                      }}
                      variant="contained"
                      className="connect-btn"
                      size="large"
                      endIcon={
                        <Iconify icon="material-symbols:arrow-right-alt-rounded" />
                      }
                      href="https://bitcointaf.com/create-account"
                      target="_blank"
                    >
                      Start Now
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box>
                    <img src={Tuffy_Pay} />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box className="banner-links mobile-dsplay-none">
            <a
              href="https://twitter.com/btaftoken"
              target="_blank"
              title="twitter"
            >
              <Iconify icon="ant-design:twitter-circle-filled" />
            </a>
            <a
              href="https://twitter.com/btaftoken"
              target="_blank"
              title="telegram"
            >
              <Iconify icon="ic:baseline-telegram" />
            </a>
            <a href="https://medium.com/@TAFtoken" target="_blank" title="">
              <Iconify icon="simple-icons:medium" />
            </a>

            <a
              href="https://www.youtube.com/channel/UCQ_UZw1Av5cSsQ6BB9Wq63g"
              target="_blank"
              title="youtube"
            >
              <Iconify icon="ri:youtube-fill" />
            </a>
            <a
              href="https://www.facebook.com/btaftoken.io"
              target="_blank"
              title="facebook"
            >
              <Iconify icon="ic:baseline-facebook" />
            </a>
          </Box>
        </Box>
      </Box>
      <Delighted />
      <WhatIs />
      <BtafPayWork />
      <Contents />
      <Merchants />
      <Contact />
      <Footer />
    </>
  );
};

export default Pay;
