import { ethers } from "ethers";
import { NETWORK } from "../config";
import getChain from "./getChain";
import stakingAbi from "./stakingAbi";
import tokenAbi from "./tokenAbi";

function connectToContracts(signer) {
  const selectedChain = getChain()[NETWORK];

  if (!selectedChain.tokenContractAddress) {
    throw new Error("Token contract address not set");
  }
  const tokenContract = new ethers.Contract(
    selectedChain.tokenContractAddress,
    tokenAbi,
    signer
  );
  if (!selectedChain.stakingContractAddress) {
    throw new Error("Staking contract address not set");
  }

  const stakingContract = new ethers.Contract(
    selectedChain.stakingContractAddress,
    stakingAbi,
    signer
  );

  const stakingContract_35 = new ethers.Contract(
    selectedChain.stakingContractAddress_35_apy,
    stakingAbi,
    signer
  );

  return [tokenContract, stakingContract, stakingContract_35];
}

export default connectToContracts;
