import { Box, Typography } from "@mui/material";
import React from "react";
import Countdown from "react-countdown";
import Ternary from "../../../../../components/ternary";

const TimeRemaining = ({ timeRemaining, setDayData }) => {
  return (
    <>
      <Typography
        variant="subtitle2"
        sx={{ textAlign: "center", color: "#fff", mt: 3 }}
      >
        Time Remaining Until Maturation Date
      </Typography>
      <Ternary
        when={timeRemaining}
        then={
          <Box sx={{ justifyContent: "center", display: "flex", mt: 1 }}>
            <Typography
              variant="caption"
              sx={{
                backgroundColor: "#cccccc1c",
                padding: "12px",
                color: "#ccc",
                borderRadius: "2rem",
                mt: 0.5,
              }}
            >
              <Countdown
                date={Date.now() + Number(timeRemaining) * 1000}
                renderer={({ hours, minutes, seconds, days }) => {
                  setDayData(days);
                  return (
                    <span>
                      {days} days, {hours} hours, {minutes} minutes, {seconds}
                      seconds
                    </span>
                  );
                }}
              />
            </Typography>
          </Box>
        }
      />
    </>
  );
};

export default TimeRemaining;
