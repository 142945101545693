import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import connectToChain from "./connectToChain";
import connectToContracts from "./connectToContracts";

const extractTimeRemaining = async (stakingContract) => {
  try {
    const timeLeft = await stakingContract.getTimeRemaining();
    return timeLeft.toNumber();
  } catch (err) {
    // console.error(err);
    return 0;
  }
};

const useSetup = () => {
  const [signerAddress, setSignerAddress] = useState("");
  const [tokenContract, setTokenContract] = useState(null);
  const [tokenContract_35, setTokenContract_35] = useState(null);
  const [stakingContract, setStakingContract] = useState(null);
  const [timeRemaining, setTimeRemaining] = useState("");

  async function setup() {
    async function connect() {
      const [signer, signerAddress] = await connectToChain();
      setSignerAddress(signerAddress);

      const [tokenContract, stakingContract, stakingContract_35] =
        await connectToContracts(signer);
      const timeRemaining = await extractTimeRemaining(stakingContract);

      const timeRemaining_35 = await extractTimeRemaining(stakingContract_35);
      setTokenContract(tokenContract);
      setStakingContract(stakingContract);
      setTimeRemaining(timeRemaining);

      return {
        signerAddress,
        tokenContract,
        stakingContract,
        timeRemaining,
        timeRemaining_35,
        stakingContract_35,
      };
    }
    if (window.ethereum) {
      try {
        // Request account access if needed
        // await window.ethereum.request({ method: "eth_requestAccounts" });
        return connect();
        console.log("MetaMask is installed and enabled.");

        // Further code to interact with MetaMask using Web3.js
      } catch (error) {
        console.log("User denied account access to MetaMask.");
        enqueueSnackbar("User denied account access to MetaMask.", {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        });
        // Show an error message or instructions to enable MetaMask
      }
    } else if (window.web3) {
      // Use Mist/MetaMask's provider
      // const web3 = new Web3(window.web3.currentProvider);
      return connect();
      console.log("MetaMask is installed and enabled.");
      // Further code to interact with MetaMask using Web3.js
    }
    // Non-dapp browsers...
    else {
      enqueueSnackbar("MetaMask is not installed or not enabled.", {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
      });
      // console.log("MetaMask is not installed or not enabled.");
      // Show an error message or instructions to install MetaMask
    }
  }
  return {
    setup,
    data: { signerAddress, tokenContract, stakingContract, timeRemaining },
  };
};

// async function getReward(idx) {
//   return await stakingContract.previewInvestorClaimReward(signerAddress, idx);
//   // return weiAmountToTokenAmount(data);
// }

export default useSetup;
