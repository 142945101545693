import { Box, Button, Grid } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import IntroSvg from "../../../assets/intro.svg";
import LogoPng from "../../../assets/logo-png.png";
import Iconify from "../components/Iconify";
import Footer from "./footer";
import BtafStake from "./stake/btafStake";
import "./stake/style.css";
import Terms from "./stake/terms";
import "./style.scss";

const Stake = () => {
  const navigate = useNavigate();
  const viewRef = useRef(null);
  useEffect(() => {
    // navigate({ pathname: "/stake", search: `?contract=${USE_CONTRACT}` });
  }, []);

  return (
    <>
      <Box className="main">
        <Box className="section banner banner-section">
          <Box className="container">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box className="banner-inner" sx={{ marginTop: "5rem" }}>
                  <h1 className="heading-xl">
                    Staking made simple via BTAFStake
                  </h1>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "none",
                      borderRadius: "1rem",
                      fontWeight: "800",
                      padding: "1rem",
                    }}
                    className="stake-token-btn"
                    size="large"
                    onClick={() => {
                      viewRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }}
                  >
                    Stake your Tokens
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      position: "absolute",
                      left: "30%",
                      right: "20%",
                      zIndex: 1,
                      top: "30%",
                    }}
                  >
                    <img className="banner-image" src={LogoPng} />
                  </Box>
                  <img className="banner-image animation-svg" src={IntroSvg} />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="container banner-column mobile-dsplay-none">
            <Box className="banner-links">
              <a href="https://twitter.com/btaftoken" target="_blank" title="">
                <Iconify icon="ant-design:twitter-circle-filled" />
              </a>
              <a href="https://t.me/taftoken" target="_blank" title="">
                <Iconify icon="ic:baseline-telegram" />
              </a>
              <a href="https://medium.com/@TAFtoken" target="_blank" title="">
                <Iconify icon="simple-icons:medium" />
              </a>

              <a
                href="https://www.youtube.com/channel/UCQ_UZw1Av5cSsQ6BB9Wq63g"
                target="_blank"
                title=""
              >
                <Iconify icon="ri:youtube-fill" />
              </a>
              <a
                href="https://www.facebook.com/btaftoken.io"
                target="_blank"
                title=""
              >
                <Iconify icon="ic:baseline-facebook" />
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box ref={viewRef}>
        <BtafStake />
      </Box>
      <Terms />
      <Footer />
    </>
  );
};

export default Stake;
