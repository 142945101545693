import { Box, Typography } from "@mui/material";
import React from "react";
import "./style.css";

const Delighted = () => {
  return (
    <>
      <Box className="main" sx={{ mt: "5rem" }}>
        <Box className="section banner banner-section what-is-pay_top-section">
          <Box className="container ">
            <h2 className="what-is-pay_top">
              <span>
                We are delighted to announce the release
                <br /> of BTAFPay. The future of DeFi <br />
                cryptocurrency payments has just landed
              </span>
            </h2>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Delighted;
