import {
  Box,
  Button,
  Menu,
  Fade,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  IconButton,
} from "@mui/material";
import { useState, useEffect } from "react";
import Iconify from "../components/Iconify";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import Drawer from "./drawer";
import MetaMaskLogo from "../../../assets/metamask.png";
import Web3 from "web3";
import Help from "../../../assets/help.png";
import DogLeg from "../../../assets/dog-leg.png";
import LogoSvg from "../../../assets/logo.png";
import useSetup from "../../../cryptoUtilities/useSetup";
import { init, useMetaMask } from "../../../store/metamask";
import PanImg from "../../../assets/pan.png";
import DexImg from "../../../assets/dex.png";
import { USE_BASE_URL } from "../../../config";
import "./style.scss";

const Layout = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openConnect, setOpenConnect] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (pathname === "/") {
      navigate("/key");
    }
  }, [pathname]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenConnect(false);
  };
  const [storeData, dispatch] = useMetaMask();
  const [connect, isConnected] = useState(false);

  const { setup } = useSetup();
  const onConnected = async () => {
    const data = await setup();
    if (data) {
      dispatch(init(data));
      isConnected(true);
      setOpenConnect(false);
    } else {
      setOpenConnect(true);
    }
  };

  useEffect(() => {
    async function checkForMetaMask() {
      // Modern dapp browsers...
      if (window.ethereum) {
        try {
          // Request account access if needed
          await window.ethereum.request({ method: "eth_requestAccounts" });
          console.log("MetaMask is installed and enabled.");
          // Further code to interact with MetaMask using Web3.js
        } catch (error) {
          console.log("User denied account access to MetaMask.");
          // Show an error message or instructions to enable MetaMask
        }
      }
      // Legacy dapp browsers...
      else if (window.web3) {
        // Use Mist/MetaMask's provider
        const web3 = new Web3(window.web3.currentProvider);
        console.log("MetaMask is installed and enabled.");
        // Further code to interact with MetaMask using Web3.js
      }
      // Non-dapp browsers...
      else {
        console.log("MetaMask is not installed or not enabled.");
        // Show an error message or instructions to install MetaMask
      }
    }

    checkForMetaMask();
  }, []);

  return (
    <>
      <header
        className="header"
        id="header"
        style={{ backgroundColor: "#212429" }}
      >
        <nav className="navbar container">
          <a href={USE_BASE_URL} target="_blank" className="brand">
            <img src={LogoSvg} width={30} style={{ objectFit: "contain" }} />

            <span
              className="mobile-size-para"
              style={{ display: "flex", alignItems: "center" }}
            >
              BTAF token
            </span>
          </a>
          <a href="https://help.btaftoken.io" target="_blank">
            <img
              src={DogLeg}
              style={{
                width: "30px",
                height: "fit-content",
                objectFit: "contain",
              }}
            />
          </a>
          <div className="burger">
            <Drawer />
          </div>
          <div className="menu" id="menu">
            <ul className="menu-inner">
              <li className="menu-item">
                <Link to="/key" className="menu-link">
                  BTAFKey
                </Link>
              </li>
              <li className="menu-item">
                <a
                  href={`${USE_BASE_URL}our-team`}
                  target="_blank"
                  className="menu-link"
                >
                  BTAF Team
                </a>
              </li>
              <li className="menu-item">
                <Link to="/stake" className="menu-link">
                  BTAFStake
                </Link>
              </li>
              <li className="menu-item">
                <Link to="/pay" className="menu-link">
                  BTAFPay
                </Link>
              </li>
              <li className="menu-item">
                <Link to="https://blog.btaftoken.io/" className="menu-link"  target="_blank">
                BTAF Blog
                </Link>
              </li>
            </ul>
          </div>
          <Box
            sx={{
              display: "grid",
              rowGap: 1,
              columnGap: 0,
              marginTop: 0,
              gridTemplateColumns: {
                xs: "repeat(2, 1fr)",
                sm: "repeat(2, 1fr)",
              },
            }}
            className="mobile-dsplay-none"
          >
            <Button
              onClick={() => {
                if (!storeData.signerAddress) {
                  onConnected();
                }
              }}
              variant="contained"
              sx={{ borderRadius: "1rem", marginLeft: 2, fontWeight: 600 }}
              size="small"
              className="connect-btn mobile-btn-layout"
            >
              {storeData.signerAddress ? "Connected" : "Connect"}
            </Button>

            <Button
              sx={{
                borderRadius: "1rem",
                marginLeft: 2,
                fontWeight: 600,
                color: "#fff",
              }}
              size="small"
              className="buy-taf-btn mobile-btn-layout"
              id="fade-button"
              aria-controls={open ? "fade-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              Buy $BTAF
            </Button>
            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
              sx={{
                "& .MuiPaper-root": {
                  backgroundColor: "#222429",
                  borderRadius: "0.5rem",
                  marginTop: "2%",
                },
              }}
            >
              <MenuItem onClick={handleClose}>
                <a
                  href="https://pancakeswap.finance/swap?outputCurrency=0xcAE3d82D63e2b0094bc959752993D3D3743B5D08"
                  target="_blank"
                  style={{ color: "#fff", fontWeight: 700 }}
                >
                  <Box display="flex">
                    <img src={PanImg} style={{ marginRight: "10px" }} />
                    PancakeSwap
                  </Box>
                </a>
              </MenuItem>
              {/* <MenuItem onClick={handleClose}>
                <a
                  href="https://www.dextools.io/app/en/bnb/pair-explorer/0x7ac443da58259a664e9bb7f4c85ed913d222a8ee"
                  target="_blank"
                  style={{ color: "#fff", fontWeight: 700 }}
                >
                  <Box display="flex">
                    <img src={DexImg} style={{ marginRight: "10px" }} />
                    DexTools
                  </Box>
                </a>
              </MenuItem> */}
            </Menu>
          </Box>
        </nav>
      </header>

      {/* dialog  */}

      <Dialog
        open={openConnect}
        onClose={handleClose}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#212429",
            borderRadius: "2rem",
          },
        }}
      >
        <DialogTitle id="responsive-dialog-title" sx={{ color: "#fff", mt: 5 }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Iconify icon="ic:outline-close" />
          </IconButton>
          <Typography variant="h5" sx={{ fontWeight: 800 }}>
            Connect Wallet
          </Typography>
        </DialogTitle>

        {/* Connect Wallet  */}
        <DialogContent>
          <Button
            variant="outlined"
            onClick={onConnected}
            sx={{
              borderRadius: "3rem",
              textTransform: "capitalize",
              mt: 1,
              fontWeight: 600,
              color: "#fff",
            }}
            fullWidth
            size="large"
            startIcon={
              <img src={MetaMaskLogo} style={{ margin: "0px 20px 0px 0px" }} />
            }
          >
            Meta Mask
          </Button>
          <Typography
            variant="subtitle2"
            fontWeight={500}
            style={{ color: "#ccc" }}
            mt={2}
            mb={2}
          >
            You don't have any crypto wallet?
          </Typography>
          <Button
            variant="contained"
            sx={{
              borderRadius: "1rem",
              textTransform: "capitalize",
              mt: 1,
              fontWeight: 600,
            }}
            className="connect-btn"
            fullWidth
            size="large"
            href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain"
            target="_blank"
          >
            Learn how to connect
          </Button>
        </DialogContent>
      </Dialog>
      {children}
      <Outlet />
    </>
  );
};

export default Layout;
