import { SnackbarProvider } from "notistack";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Key from "./pages/final/pages/key";
import Layout from "./pages/final/pages/layouts";
import NoPage from "./pages/final/pages/noPage";
import Pay from "./pages/final/pages/pay";
import Stake from "./pages/final/pages/stake";
import MetaMask from "./store/metamask";

function App() {
  return (
    <>
      <MetaMask>
        <SnackbarProvider />
        <BrowserRouter>
          <Layout>
            <Routes>
              <Route path="/">
                <Route path="stake" element={<Stake />} />
                <Route path="key" element={<Key />} />
                <Route path="pay" element={<Pay />} />
                <Route path="*" element={<NoPage />} />
              </Route>
            </Routes>
          </Layout>
        </BrowserRouter>
      </MetaMask>
    </>
  );
}

export default App;
