import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import Ternary from "../../../../../../components/ternary";
import Iconify from "../../../../components/Iconify";

const StakeTable = ({
  stakingInfo,
  setNumberOfStake,
  getNumberOfStake,
  setRewardDialogData,
  handleClickOpenClaim,
  handleClickOpenUnstake,
}) => {
  const [refreshing, setRefreshing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <>
      <Box className="empty-box">
        <Box>
          <div style={{ overflowX: "auto" }}>
            <table>
              <tr>
                <th>Stake #</th>
                <th>Start Date</th>
                <th>Staked Amount</th>
                <th>Available Rewards</th>
                <th>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    Burnt Amount
                    <Box sx={{ textAlign: "right" }}>
                      <Tooltip
                        title={
                          <Box sx={{ borderRadius: "10px" }}>
                            <Typography
                              variant="subtitle2"
                              fontWeight={700}
                              style={{ fontSize: "0.775rem" }}
                              mt={1}
                            >
                              The “Burnt Amount” is the fee that you have
                              already paid for unstaking your tokens before the
                              contract maturation date. If there is a “Burnt
                              Amount” here it means that you have already
                              unstaked all your tokens from this line.
                            </Typography>
                          </Box>
                        }
                        enterTouchDelay={0}
                      >
                        <IconButton
                          size="small"
                          sx={{
                            width: 25,
                            height: 25,
                            color: "#cccccc8c",
                          }}
                        >
                          <Iconify
                            icon="mdi:question-mark-circle"
                            sx={{
                              "& .MuiBox-root": {
                                "& .MuiButtonBase-root": {
                                  margin: "0px -5px -70px 0px",
                                },
                              },
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </th>
                <th></th>
                <th>
                  <Box
                    sx={{
                      width: "max-content",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Refresh My Details
                    {refreshing ? (
                      <IconButton>
                        <CircularProgress size={12} />
                      </IconButton>
                    ) : (
                      <IconButton
                        sx={{ color: "#e843cf" }}
                        aria-label="upload picture"
                        component="label"
                        onClick={() => {
                          setRefreshing(true);
                          setTimeout(() => {
                            setNumberOfStake(0);
                            getNumberOfStake().then(() => setRefreshing(false));
                          }, [1500]);
                          enqueueSnackbar("Refreshed", {
                            variant: "info",
                            anchorOrigin: {
                              horizontal: "right",
                              vertical: "top",
                            },
                          });
                        }}
                        size="small"
                      >
                        <Iconify icon="ic:sharp-refresh" />
                      </IconButton>
                    )}
                  </Box>
                </th>
              </tr>
              {stakingInfo.map(
                ({
                  id,
                  staker,
                  amount,
                  startDate,
                  preview,
                  reward,
                  stakeBurnt,
                  unStaked,
                }) => {
                  console.log(unStaked);
                  return (
                    <tr>
                      <td>{id}</td>
                      <td>{new Date(startDate).toLocaleDateString("en-GB")}</td>
                      <td>{amount}</td>
                      <td>{reward ?? 0}</td>
                      <td>{stakeBurnt}</td>
                      <td>
                        <Box>
                          <Box sx={{ textAlign: "right" }}>
                            <Tooltip
                              title={
                                <Box sx={{ borderRadius: "10px" }}>
                                  <Typography
                                    variant="subtitle2"
                                    fontWeight={700}
                                    style={{ fontSize: "0.775rem" }}
                                    mt={1}
                                  >
                                    Use this to view how many rewards you would
                                    receive if you were to claim them now. You
                                    can only claim the full available amount,
                                    not a portion. There is no fee to claim
                                    rewards and you can claim the available
                                    amount at any time. After you view the
                                    information, you can either proceed to claim
                                    or cancel the transaction.
                                  </Typography>
                                </Box>
                              }
                              enterTouchDelay={0}
                            >
                              <IconButton
                                size="small"
                                sx={{
                                  width: 25,
                                  height: 25,
                                  color: "#cccccc8c",
                                }}
                              >
                                <Iconify
                                  icon="mdi:question-mark-circle"
                                  sx={{
                                    "& .MuiBox-root": {
                                      "& .MuiButtonBase-root": {
                                        margin: "0px -5px -70px 0px",
                                      },
                                    },
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <Button
                            size="small"
                            className="btn-stake-card"
                            sx={{
                              textTransform: "capitalize",
                              width: "max-content",
                            }}
                            onClick={() => {
                              if (reward == 0) {
                                enqueueSnackbar(
                                  "You have no available rewards to claim!",
                                  {
                                    variant: "warning",
                                    anchorOrigin: {
                                      horizontal: "right",
                                      vertical: "bottom",
                                    },
                                  }
                                );
                              } else {
                                setRewardDialogData({
                                  amount,
                                  id,
                                  staker,
                                });
                                handleClickOpenClaim();
                              }
                            }}
                            fullWidth
                          >
                            Claim Rewards
                          </Button>
                        </Box>
                      </td>
                      <td>
                        <Box>
                          <Box sx={{ textAlign: "right" }}>
                            <Tooltip
                              title={
                                <Box sx={{ borderRadius: "10px" }}>
                                  <Typography
                                    variant="subtitle2"
                                    fontWeight={700}
                                    style={{ fontSize: "0.775rem" }}
                                    mt={1}
                                  >
                                    Use this to view the penalty you would incur
                                    if you were to unstake your tokens early –
                                    that is before maturation of the contract.
                                    There is no penalty if you unstake your
                                    tokens after the contract maturation date.
                                    After you view the information, you can
                                    either proceed to unstake or cancel the
                                    transaction.
                                  </Typography>
                                </Box>
                              }
                              enterTouchDelay={0}
                            >
                              <IconButton
                                size="small"
                                sx={{
                                  width: 25,
                                  height: 25,
                                  color: "#cccccc8c",
                                }}
                              >
                                <Iconify
                                  icon="mdi:question-mark-circle"
                                  sx={{
                                    "& .MuiBox-root": {
                                      "& .MuiButtonBase-root": {
                                        margin: "0px -5px -70px 0px",
                                      },
                                    },
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                          <Button
                            size="small"
                            className="btn-stake-card"
                            sx={{ textTransform: "capitalize" }}
                            disabled={unStaked}
                            onClick={() => {
                              if (!unStaked) {
                                setRewardDialogData({
                                  amount,
                                  id,
                                  staker,
                                });
                              }
                              handleClickOpenUnstake();
                            }}
                            fullWidth
                          >
                            <Ternary
                              when={unStaked}
                              then="Unstaked"
                              otherwise="Unstake Tokens"
                            />
                            {/* Unstake Tokens */}
                          </Button>
                        </Box>
                      </td>
                    </tr>
                  );
                }
              )}
            </table>
          </div>
        </Box>
      </Box>
    </>
  );
};

export default StakeTable;
