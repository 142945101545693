import {
  Backdrop,
  Box,
  Card,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Ternary from "../../../../components/ternary";
import useSetup from "../../../../cryptoUtilities/useSetup";
import { init, useMetaMask } from "../../../../store/metamask";
import Buttons from "./components/buttons";
import CalculateReward from "./components/calculateReward";
import Claim from "./components/dialogs/claim";
import Confirm from "./components/dialogs/confirm";
import ConnectWallet from "./components/dialogs/connectWallet";
import UnStake from "./components/dialogs/unStake";
import MyStakingInfo from "./components/myStakingInfo";
import Stake from "./components/stake";
import StakeTable from "./components/table";
import TimeRemaining from "./components/timeRemaining";
import Title from "./components/title";
import useStake from "./hooks/useStake";

const All = ({
  stakingContract,
  timeRemaining,
  disableStake = false,
  title,
  subTitle,
  feePercent,
  dailyReturn,
  className,
}) => {
  const [data, dispatch] = useMetaMask();
  const { setup } = useSetup();
  const { signerAddress } = data;
  const [openConnect, setOpenConnect] = useState(false);
  const [open, setOpen] = useState(false);
  const [openCalculateReward, setOpenCalculateReward] = useState(false);
  const [openAreyouSure, setOpenAreyouSure] = useState(false);

  const [openLoader, setOpenLoader] = useState(false);

  const handleClickOpenCalculateReward = () => {
    if (connect) {
      setOpenCalculateReward(true);
    } else {
      setOpenConnect(true);
    }
  };

  const handleClickOpen = () => {
    if (connect) {
      setOpen(true);
    } else {
      setOpenConnect(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
    setOpenClaim(false);
    setOpenUnstake(false);
    setOpenCalculateReward(false);
    setOpenAreyouSure(false);
    setOpenLoader(false);
    setOpenConnect(false);
  };
  const [connect, isConnected] = useState(false);

  useEffect(() => {
    if (connect) setOpenConnect(false);
  }, [connect]);

  const onConnected = async () => {
    const data = await setup();
    if (data) {
      dispatch(init(data));
      isConnected(true);
      setOpenConnect(false);
    } else {
      setOpenConnect(true);
    }
  };
  // Refresh

  useEffect(() => {
    if (signerAddress) isConnected(signerAddress);
  }, [signerAddress]);

  const [openClaim, setOpenClaim] = useState(false);

  const handleClickOpenClaim = () => {
    setOpenClaim(true);
  };

  const [openUnstake, setOpenUnstake] = useState(false);

  const handleClickOpenUnstake = () => {
    setOpenUnstake(true);
  };

  const [rewardDialogData, setRewardDialogData] = useState([]);

  const [dayData, setDayData] = useState(0);

  const {
    depositStake,
    stakingInfo,
    burnAmount,
    withdrawAmount,
    withdrawStake,
    reward,
    getNumberOfStake,
    claimReward,
    setNumberOfStake,
  } = useStake(stakingContract, {
    openClaim,
    openUnstake,
    handleClose,
    setOpenAreyouSure,
    setOpenClaim,
    rewardDialogData,
    setOpenLoader,
  });
  return (
    <Box mt={3}>
      <Card className={className} sx={{ backgroundColor: "#d6d6d600" }}>
        <Box mt={4}>
          <Title title={title} subTitle={subTitle} />
          <TimeRemaining
            setDayData={setDayData}
            timeRemaining={timeRemaining}
          />

          <Buttons
            handleClickOpen={handleClickOpen}
            handleClickOpenCalculateReward={handleClickOpenCalculateReward}
            onConnected={onConnected}
            disableStake={disableStake}
          />

          <MyStakingInfo />
        </Box>
        <Ternary
          when={connect}
          then={
            <StakeTable
              getNumberOfStake={getNumberOfStake}
              handleClickOpenClaim={handleClickOpenClaim}
              handleClickOpenUnstake={handleClickOpenUnstake}
              setNumberOfStake={setNumberOfStake}
              setRewardDialogData={setRewardDialogData}
              stakingInfo={stakingInfo}
            />
          }
          otherwise={
            <Box className="empty-box">
              <Typography fontWeight={500}>
                YOU HAVE NOT STAKED IN THIS CONTRACT YET
              </Typography>
            </Box>
          }
        />
      </Card>
      {/* Deposite Stake  */}

      <Stake
        feePercent={feePercent}
        expired={disableStake}
        open={open}
        onClose={handleClose}
        depositStake={depositStake}
      />

      {/* reward dialog  */}

      <CalculateReward
        dailyReturn={dailyReturn}
        feePercent={feePercent}
        onClose={handleClose}
        open={openCalculateReward}
        dayData={dayData}
      />

      <Claim
        reward={reward}
        claimReward={claimReward}
        onClose={handleClose}
        open={openClaim}
      />

      <UnStake
        burnAmount={burnAmount}
        withdrawAmount={withdrawAmount}
        onClose={handleClose}
        open={openUnstake}
        setOpenAreyouSure={setOpenAreyouSure}
      />
      {/* are your sure  */}
      <Confirm
        onClose={handleClose}
        open={openAreyouSure}
        withdrawStake={withdrawStake}
      />

      {/* connect check wallet  */}

      <ConnectWallet
        onClose={handleClose}
        onConnected={onConnected}
        open={openConnect}
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 9 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

const Wrapper = () => {
  const [data] = useMetaMask();

  const {
    stakingContract,
    timeRemaining,
    stakingContract_35,
    timeRemaining_35,
  } = data;

  return (
    <>
      <All
        title="90-DAY /35% APR"
        className="all-card-35 all-card-35"
        subTitle="Accumulated Annual Percentage Rate (APR)"
        stakingContract={stakingContract_35}
        timeRemaining={timeRemaining_35}
        feePercent={0.025}
        dailyReturn={0.095}
      />

      <All
        className="all-card"
        title="90-DAY /65% APR"
        subTitle="Accumulated Annual Percentage Rate (APR)"
        stakingContract={stakingContract}
        timeRemaining={timeRemaining}
        disableStake
        feePercent={0.025}
        dailyReturn={0.17}
      />
    </>
  );
};

export default Wrapper;
