import React, { useRef } from "react";
import Iconify from "../components/Iconify";
import "./style.scss";
import LogoPng from "../../../assets/logo-png.png";
import LogoSvg from "../../../assets/logo.png";
import HelpSvg from "../../../assets/help.png";
import IntroSvg from "../../../assets/intro.svg";
import { Button, Box, Grid } from "@mui/material";
import WhatIs from "./key/whatIs";
import TierLevels from "./key/tierLevels";
import BuyBTAF from "./key/buyBTAF";
import Secure from "./key/secure";
import Footer from "./footer";

const Key = () => {
  const viewRef = useRef(null);
  return (
    <>
      <Box className="main">
        <Box className="section banner banner-section">
          <Box className="container">
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box className="banner-inner" sx={{ marginTop: "8rem" }}>
                  <h1 className="heading-xl">BTAFKey - Your Unique Tier</h1>
                  <Button
                    sx={{
                      textTransform: "none",
                      borderRadius: "1rem",
                      fontWeight: "800",
                      padding: "1rem",
                    }}
                    variant="contained"
                    className="subscribe-btn"
                    size="large"
                    href="https://taftoken.eo.page/taftoken"
                    target="_blank"
                    onClick={() => {
                      viewRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }}
                  >
                    Subscribe to our newsletter
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box sx={{ position: "relative" }}>
                  <Box
                    sx={{
                      position: "absolute",
                      left: "30%",
                      right: "20%",
                      zIndex: 1,
                      top: "30%",
                    }}
                  >
                    <img className="banner-image" src={LogoPng} />
                  </Box>
                  <img className="banner-image animation-svg" src={IntroSvg} />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="banner-links mobile-dsplay-none">
            <a
              href="https://twitter.com/btaftoken"
              target="_blank"
              title="twitter"
            >
              <Iconify icon="ant-design:twitter-circle-filled" />
            </a>
            <a
              href="https://twitter.com/btaftoken"
              target="_blank"
              title="telegram"
            >
              <Iconify icon="ic:baseline-telegram" />
            </a>
            <a href="https://medium.com/@TAFtoken" target="_blank" title="">
              <Iconify icon="simple-icons:medium" />
            </a>

            <a
              href="https://www.youtube.com/channel/UCQ_UZw1Av5cSsQ6BB9Wq63g"
              target="_blank"
              title="youtube"
            >
              <Iconify icon="ri:youtube-fill" />
            </a>
            <a
              href="https://www.facebook.com/btaftoken.io"
              target="_blank"
              title="facebook"
            >
              <Iconify icon="ic:baseline-facebook" />
            </a>
          </Box>
        </Box>
      </Box>
      <WhatIs />
      <TierLevels />
      <BuyBTAF />
      <Secure />
      <Footer />
    </>
  );
};

export default Key;
