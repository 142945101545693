import detectEthereumProvider from "@metamask/detect-provider";
import { ethers } from "ethers";
import { NETWORK } from "../config";
import getChain from "./getChain";

async function connectToChain() {
  let selectedChain = getChain()[NETWORK];

  // For more information about using metamask with web3, see: https://docs.metamask.io/guide/
  const metamaskProvider = await detectEthereumProvider();
  await metamaskProvider.request({ method: "eth_requestAccounts" });
  // Check the chain ID to ensure we are on the correct network
  const chainId = await metamaskProvider.request({ method: "eth_chainId" });
  // If we are not on bsc testnet, switch to it
  // This is a good resource for chain IDs: https://chainlist.org/
  if (chainId !== selectedChain.chainId) {
    try {
      await metamaskProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: selectedChain.chainId }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          // Add chain to metamask
          await metamaskProvider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: selectedChain.chainId,
                chainName: selectedChain.chainName,
                rpcUrls: selectedChain.rpcUrls, // ['https://bsctestapi.terminet.io/rpc'],
              },
            ],
          });
        } catch (addError) {
          // handle "add" error
        }
      }
      // handle other "switch" errors
    }
  }
  // Instantiate the ethers provider and signer
  const ethersProvider = new ethers.providers.Web3Provider(metamaskProvider);
  const signer = ethersProvider.getSigner();
  const address = await signer.getAddress();
  return [signer, address];
}

export default connectToChain;
