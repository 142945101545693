import { Typography } from "@mui/material";
import React from "react";

const Title = ({ title = "", subTitle = "" }) => {
  return (
    <>
      <Typography
        variant="h6"
        sx={{ color: "#fff", textAlign: "center", fontWeight: "700" }}
      >
        {title}
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{ textAlign: "center", color: "#989898" }}
      >
        {subTitle}
      </Typography>
    </>
  );
};

export default Title;
